$base-font-family: 'Roboto', sans-serif;
$body-font-family: 'Roboto', sans-serif;
$code-font-family: "Source Code Pro", monospace;

$brand-color: black;

$container-width: 1100px;
$tablet-width: 600px;
$phone-width: 480px;

$header-thickness: 56px;

$icon-transition-time: 1.0s;
$envelope-color: #f39c12;
$twitter-color: #00aced;
$instagram-color: #375989;
$github-color: #343434;
$linkedin-color: #0073a4;
$facebook-color: #3d5b99;
$google-color: #e64a41;
$pinterest-color: #bd081c;
$medium-color: #00AB6C;
$codepen-color: black;
$rss-color: #ff6600;

@import "-sections-dir";

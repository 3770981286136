/*
  Site container
*/

.container {
  width: $container-width;
  margin-left: auto;
  margin-right: auto;
}
.container a {
  text-decoration: underline;
}
.content-wrapper {
  padding-top: $header-thickness+75px;
  margin: auto;
  display: block;
  width: $container-width - 20px;
}
.content-wrapper h1 {
  margin: 20px 0px 5px;
}
@media (max-width: $container-width) {
  .container {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
  .content-wrapper {
    padding-top: $header-thickness+10px;
    margin: auto;
    display: block;
    width: 95vw;
  }
}
